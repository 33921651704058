import React from 'react';
import PropTypes from 'prop-types';
import AppToolbar from "./AppToolbar.js";
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';

import SortableTable from "./SortableTable.js";

const styles = theme => ({
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing.unit * 2,
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  barButton: {
      marginRight: 5
  }
});

class ViewReturnsPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, match, title } = this.props;

        return (<>
                  <AppBar position="static" color="primary" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <div>
                            <Typography className={classes.title} variant="h6" color="inherit" noWrap>{title}</Typography>
                        </div>
                      <div>
                          {this.props.headerControls}
                      </div>
                    </Toolbar>
                  </AppBar>
                  <SortableTable {...this.props} />
                </>);
    }
}

export default withStyles(styles)(ViewReturnsPage);
