import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import AppToolbar from "./AppToolbar.js";
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import darkTheme from "./DarkTheme.js";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import SortableTableWithHeader from "./SortableTableWithHeader.js";
import axios from "axios";
import red from '@material-ui/core/colors/red';
import checkVATNumber from "./jsvat.js";

const styles = theme => {
    return {
      root: {
          flexGrow: 1,
          marginTop:70,
          padding: 10,
      },
      paper: {
          //width: 400,
          paddingLeft:10,
          paddingRight:10,
          paddingBottom:10
      },
      input: {
          width: "100%"
      },
      controls: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit
      },
      frame: {
      },
      table: {
          height: "100%",
      },
      invalid: {
          backgroundColor: red[700]
      }
  }
};

const VIESRow = withStyles(styles)(props => {
    const {row, index, classes} = props;
    const { valid } = row;
    return <TableRow key={index} className={!valid ? classes.invalid : null}>
              <TableCell align="left">{row.vrn}</TableCell>
              <TableCell align="left">{row.valid ? "Yes" : "No"}</TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.address}</TableCell>
              <TableCell align="left">{row.cachedDate}</TableCell>
            </TableRow>
});

let checked = [];

const VIES_API = "http://ec2-34-254-239-196.eu-west-1.compute.amazonaws.com:8083/api";

function checkVIES(num) {
    if (checkVATNumber(num)) {
        if (typeof checked[num] == 'undefined') {
            checked[num] = axios(VIES_API + "/vies/" + num);
        }
    } else {
        checked[num] = {vrn: num, valid: false};
    }

    return checked[num];
}

class App extends Component {
    state = {
        vatNumsStr: "",
        data: []
      };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClick = name => async event => {
      const {vatNumsStr} = this.state;

    if (name == "check") {
        let vatIds = vatNumsStr.split("\n").map(x => x.toUpperCase().trim()).filter(x => x != "");

        let data = [];
        let i = 0;
        let count = 0;

        const MAX_REQ = 30;

        for (i = MAX_REQ; i < vatIds.length + MAX_REQ; i += MAX_REQ) {
            let reqs = [];
            for (let j = i - MAX_REQ; j < vatIds.length && j < i; j++) {
                reqs.push(checkVIES(vatIds[j]));
            }

            let res = await Promise.all(reqs);
            for (var x of res) {
                if (!x.data) {
                    data.push({ id: count, vrn: x.vrn, valid: false, name: "", address: "", cachedDate: "Invalid format" });
                } else {
                    x = x.data;

                    data.push({ id: count, vrn: x.countryCode + x.vatNumber, valid: x.valid, name: x.name, address: x.address, cachedDate: x.requestDate });
                }

                count++;
            }
        };

        this.setState({
          data: data,
        });
    }
  };

  render() {
      const {classes} = this.props;
      const {spacing, data} = this.state;

    return (
      <div className="App">
          <MuiThemeProvider theme={darkTheme}>
              <CssBaseline />
              <AppToolbar position="fixed" title="VIES Checker"/>
              <div className={classes.root}>
                <Paper>
                    <Grid container spacing={0} justify="center">
                        <Grid item lg={3} md={2} xs={12}>
                            <div className={classes.controls}>
                                <TextField
                                  id="vat-nums"
                                  label="VAT Numbers"
                                  multiline
                                  rows="16"
                                  value={this.state.vatNumsStr}
                                  onChange={this.handleChange('vatNumsStr')}
                                  className={classes.input}
                                  margin="normal"
                                  variant="filled"
                                />
                            <Button fullWidth variant="contained" color="primary" onClick={this.handleClick("check")}>Check</Button>
                            </div>
                        </Grid>
                        <Grid item lg={9} md={10} xs={12}>
                            <SortableTableWithHeader
                                className={classes.table}
                                headers={
                                    [{ id: 'vrn', numeric: false, disablePadding: false, label: 'VAT Number' },
                                    { id: 'valid', numeric: false, disablePadding: false, label: 'Valid' },
                                    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
                                    { id: 'address', numeric: false, disablePadding: false, label: 'Address'},
                                    { id: 'cachedDate', numeric: false, disablePadding: false, label: 'Cached Date'}
                                ]}
                                data={data}
                                paginate={true}
                                paginationOptions={[5, 10, 25, 50, 100]}
                                paginationStartRows={10}
                                row={(row, i) => {
                                    return <VIESRow row={row} index={i} />
                                }}
                                title="Results"
                                />
                        </Grid>
                    </Grid>
                </Paper>
             </div>
        </MuiThemeProvider>
      </div>);
  }
}

export default withStyles(styles)(App);
